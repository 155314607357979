import React from "react"

const AddOn = () => {
  return (
    <section className="secureof variousblock pt-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12  text-center">
          <h2 className="main-heading"><span className="bluecolor">Blockchains We</span> use for NFT Minting Website development</h2>
          </div>
        </div>
        <div className="binanceflexbox">
          <div className="card" >
            <div className="binancecont">
              <img
                width="75"
                height="63"
                src="https://coinsclone.mo.cloudinary.net/images/nft-minting/eth.png"
                alt="Ethereum"
              />
              <p className="pharagraph">Ethereum</p>
            </div>
          </div>
          <div className="card">
            <div className="binancecont">
                <img
                  width="75"
                  height="63"
                  src="https://coinsclone.mo.cloudinary.net/images/nft-minting/bnb.png"
                  alt="BNB Chain"
                />
                <p className="pharagraph">BNB Chain</p>
            </div>
          </div>
          <div className="card" >
            <div className="binancecont">
                <img
                  width="75"
                  height="63"
                  src="https://coinsclone.mo.cloudinary.net/images/nft-minting/tron.png"
                  alt="Tron"
                />
                <p className="pharagraph" >Tron</p>
            </div>
          </div>
        </div>
        <div className="binanceflexbox">
          <div className="card" >
            <div className="binancecont">
                <img
                  width="75"
                  height="63"
                  src="https://coinsclone.mo.cloudinary.net/images/nft-minting/polygon.png"
                  alt="Polygon"
                />
                <p className="pharagraph" >Polygon</p>
            </div>
          </div>
          <div className="card">
            <div className="binancecont">
                <img
                  width="75"
                  height="63"
                  src="https://coinsclone.mo.cloudinary.net/images/nft-minting/solana.png"
                  alt="Solana"
                />
                <p className="pharagraph">Solana</p>
            </div>
          </div>
          <div className="card" >
            <div className="binancecont">
                <img
                  width="75"
                  height="63"
                  src="https://coinsclone.mo.cloudinary.net/images/nft-minting/waves.png"
                  alt="Waves"
                />
                <p className="pharagraph">Waves</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AddOn
